import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {IsLoggedGuard} from 'src/app/guard/isLogged.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { //quando un utente non è ancora loggato
    path: 'free-trial-request',
    redirectTo: 'free-trial',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('src/app/pages/desktop/common/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('src/app/pages/desktop/common/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'account-settings',
    loadChildren: () => import('src/app/pages/desktop/common/account-settings/account-settings.module').then(m => m.AccountSettingsPageModule)
  },
  {
    path: 'poi-add',
    loadChildren: () => import('src/app/pages/desktop/common/poi-add/poi-add.module').then(m => m.PoiAddPageModule)
  },
  {
    path: 'user-data',
    loadChildren: () => import('src/app/pages/desktop/common/user-data/user-data.module').then(m => m.UserDataPageModule)
  },
  {
    path: 'map',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/map/map.module').then(m => m.MapPageModule)
  },
  {
    path: 'report',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/report/report.module').then(m => m.ReportPageModule)
  },
  {
    path: 'alarms',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/alarms/alarms.module').then(m => m.AlarmsPageModule)
  },
  {
    path: 'poi-detail',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/poi-detail/poi-detail.module').then(m => m.PoiDetailPageModule)
  },
  {
    path: 'alarm-speed',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/alarm-speed/alarm-speed.module').then(m => m.AlarmSpeedPageModule)
  },
  {
    path: 'alarms-poi',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/alarms-poi/alarms-poi.module').then(m => m.AlarmsPoiPageModule)
  },
  {
    path: 'alarm-unplug',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/alarm-unplug/alarm-unplug.module').then(m => m.AlarmUnplugPageModule)
  },
  {
    path: 'alarm-offline',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/alarm-offline/alarm-offline.module').then(m => m.AlarmOfflinePageModule)
  },
  {
    path: 'alarm-movement',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/alarm-movement/alarm-movement.module').then(m => m.AlarmMovementPageModule)
  },
  {
    path: 'alarm-parking',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/alarm-parking/alarm-parking.module').then(m => m.AlarmParkingPageModule)
  },
  {
    path: 'alarm-poi',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/alarm-poi/alarm-poi.module').then(m => m.AlarmPoiPageModule)
  },
  {
    path: 'alarm-input',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/alarm-input/alarm-input.module').then(m => m.AlarmInputPageModule)
  },
  {
    path: 'alert',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/alert/alert.module').then(m => m.AlertPageModule)
  },
  {
    path: 'cars',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/cars/cars.module').then(m => m.CarsPageModule)
  },
  {
    path: 'gps-input',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/gps-input/gps-input.module').then(m => m.GpsInputPageModule)
  },
  {
    path: 'car-fields',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/car-fields/car-fields.module').then(m => m.CarFieldsPageModule)
  },
  {
    path: 'subscription',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/subscription/subscription.module').then(m => m.SubscriptionPageModule)
  },
  {
    path: 'engine-lock',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/engine-lock/engine-lock.module').then(m => m.EngineLockPageModule)
  },
  {
    path: 'billing',
    redirectTo: 'payment',
    pathMatch: 'full'
  },
  {
    path: 'problems-resolution',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/problems-resolution/problems-resolution.module').then(m => m.ProblemsResolutionPageModule)
  },
  {
    path: 'subscription',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/subscription/subscription.module').then(m => m.SubscriptionPageModule)
  },
  {
    path: 'general-info',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/general-info/general-info.module').then(m => m.GeneralInfoPageModule)
  },
  {
    path: 'not-permitted',
    loadChildren: () => import('src/app/pages/desktop/common/not-permitted/not-permitted.module').then(m => m.NotPermittedPageModule)
  },
  {
    path: 'notification-details',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/notification-details/notification-details.module').then(m => m.NotificationDetailsPageModule)
  },
  {
    path: 'notifications',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'notifications-filter',
    loadChildren: () => import('src/app/pages/desktop/common/notifications-filter/notifications-filter.module').then(m => m.NotificationsFilterPageModule)
  },
  {
    path: 'payment-method',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/payment-method/payment-method.module').then(m => m.PaymentMethodPageModule)
  },
  {
    path: 'payment-problems',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/payment-problems/payment-problems.module').then(m => m.PaymentProblemsPageModule)
  },
  {
    path: 'phone-add',
    loadChildren: () => import('src/app/pages/desktop/common/phone-add/phone-add.module').then(m => m.PhoneAddPageModule)
  },
  {
    path: 'routes-history',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/routes-history/routes-history.module').then(m => m.RoutesHistoryPageModule)
  },
  {
    path: 'setup-gps',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/setup-gps/setup-gps.module').then(m => m.SetupGpsPageModule)
  },
  {
    path: 'shop',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/shop/shop.module').then(m => m.ShopPageModule)
  },
  {
    path: 'ticket-detail',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/ticket-detail/ticket-detail.module').then(m => m.TicketDetailPageModule)
  },
  {
    // path: 'tickets-whatsapp',
    path: 'tickets',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/tickets-whatsapp/tickets-whatsapp.module').then(m => m.TicketsWhatsappPageModule)
  },
  {
    path: 'users',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/users/users.module').then(m => m.UsersPageModule)
  },
  {
    path: 'user-add',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/user-add/user-add.module').then(m => m.UserAddPageModule)
  },
  {
    path: 'referral',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/referral/referral.module').then(m => m.ReferralPageModule)
  },
  {
    path: 'payment',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path:'stripe-payment',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/stripe-payment-methods/stripe-payment-methods.module').then( m =>m.StripePaymentMethodsPageModule)
  },
  {
    path:'invoice-details',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/invoice-details/invoice-details.module').then( m => m.InvoiceDetailsPageModule),
  },
  {
    path:'detail-invoice',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/detail-invoice/detail-invoice.module').then( m => m.DetailInvoicePageModule),
  },{
    path:'payment-methods',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/payment-methods/payment-methods.module').then( m => m.PaymentMethodsPageModule),
  },
  {
    path:'recharge-balance',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/recharge-balance/recharge-balance.module').then( m => m.RechargeBalancePageModule)
  },
  {
    path:'pay-invoice',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/pay-invoice/pay-invoice.module').then( m => m.PayInvoicePageModule)
  },
  {
    path: 'finalize-order',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/finalize-order/finalize-order.module').then( m => m.FinalizeOrderPageModule)
  },
  {
    path: 'order-history',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/order-history/order-history.module').then(m => m.OrderHistoryPageModule)
  },
  {
    path: 'free-trial',
    loadChildren: () => import('src/app/pages/desktop/common/free-trial-request/free-trial-request.module').then(m => m.FreeTrialRequestPageModule)
  },
  {
    path: 'personal-data',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/personal-data/personal-data.module').then(m => m.PersonalDataPageModule)
  },
  {
    path: 'login-modal',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/login-modal/login-modal.module').then(m => m.LoginModalPageModule)
  },
  {
    path: 'manage-fleet',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/fleet-manager/fleet-manager.module').then(m => m.FleetManagerPageModule)
  },
  {
    path: 'create-ticket',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/create-ticket/create-ticket.module').then(m => m.CreateTicketPageModule)
  },
  {
    path: 'activation',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/activation/activation.module').then( m => m.ActivationPageModule)
  },
  {
    path: 'change-password',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'chart',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/chart/chart.module').then( m => m.ChartPageModule)
  },
  {
    path: 'marker-setting',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/marker-settings/marker-settings.module').then( m => m.MarkerSettingsPageModule)
  },
  {
    path: 'deadline',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/deadline/deadline.module').then( m => m.DeadlinePageModule)
  },
  {
    path: 'add-deadline-target',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/add-deadline-target/add-deadline-target.module').then(m =>m.AddDeadlineTargetPageModule)
  },
  {
    path: 'add-deadline',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/add-deadline/add-deadline.module').then(m =>m.AddDeadlinePageModule)
  },
  {
    path: 'add-deadline-cost',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/add-deadline-cost/add-deadline-cost.module').then(m =>m.AddDeadlineCostPageModule)
  },
  {
    path: 'deadline-detail',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/deadline-detail/deadline-detail.module').then(m =>m.DeadlineDetailPageModule)
  },
  {
    path: 'batch-detail',
    loadChildren: () => import('src/app/pages/desktop/common/report-batch-detail/report-batch-detail.module').then(m => m.ReportBatchDetailPageModule)
  },
  {
    path: 'batch-detail-fiull',
    loadChildren: () => import('src/app/pages/desktop/common/report-batch-detail-full/report-batch-detail-full.module').then(m => m.ReportBatchDetailFullPageModule)
  },
  {
    path: 'report-export',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/report-export-modal/report-export-modal.module').then(m =>m.ReportExportModalPageModule)
  },
  {
    path: 'report-requested',
    canActivate: [IsLoggedGuard],
    loadChildren: () => import('src/app/pages/desktop/common/report-batch-requested/report-batch-requested.module').then(m =>m.ReportBatchRequestedPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
