import {Injectable} from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate, Router, RouterStateSnapshot
} from "@angular/router";
import {AuthService} from "../services/common/auth.service";
import {DataService} from "../services/common/data.service";

@Injectable({
  providedIn: 'root'
})
export class IsLoggedGuard implements CanActivate {
  lang: any;
  constructor(
    private router: Router,
    private data: DataService
  ){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    if(!AuthService.isLogged){
      this.router.navigate(['/login'], {replaceUrl: true});
      return false;
    }else{
      return true;
    }
  }

}
