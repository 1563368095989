export const firebaseConfig = {
  apiKey: "AIzaSyDll4bBGPDH7zG_atI4Vjdp9j39oAa-55E",
  authDomain: "white-label-dev-635e0.firebaseapp.com",
  databaseURL: "https://white-label-dev-635e0.firebaseio.com",
  projectId: "white-label-dev-635e0",
  storageBucket: "white-label-dev-635e0.appspot.com",
  messagingSenderId: "10862540980",
  appId: "1:10862540980:web:e039d565d29b913badbfe1",
  measurementId: "G-7EFTP90YS4"
}
