import { Injectable } from '@angular/core';
import {
  ActivatedRoute, NavigationExtras,
  Router
} from "@angular/router";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  static router: Router;
  static route: ActivatedRoute;
  static location: any;

  constructor() { }

  static initialize(route: ActivatedRoute, router: Router, location: any){
    NavigationService.route = route;
    NavigationService.router = router;
    NavigationService.location = location;
  }

  static async navigate(url: string, isRoot?: boolean, isRelativeUrl?: boolean, data?: any){
    await NavigationService.router.navigate(
      [url],
      <NavigationExtras> {replaceUrl: isRoot, relativeTo: this.route, queryParams: data, queryParamsHandling: 'merge'}
      );
  }

  static goBack(){
    NavigationService.location.back();
  }

  static foForward(){
    NavigationService.location.forward();
  }

  static getQueryParams(): Promise<any>{
    return new Promise((resolve) => {
      try{
        this.route.queryParams.subscribe(params=> {
          return resolve(params);
        });
      }catch(err){
        return resolve({});
      }
    });
  }

  static async clearQueryParams(){
    await this.router.navigate([], { queryParams: {} });
  }
}
