import {Partecipant} from "src/app/classes/common/partecipant";
import * as moment from 'moment';

export class Deadline {
  _id: string;
  id_organization: string;
  type: number;
  id_deadline_target: string;
  id_participant: string;
  title: string;
  description: string;
  description_cost: string;
  completed: boolean
  created: number
  completation_date: number
  expiration_date: number
  completation_km: number
  expiration_km: number
  recurring: boolean
  recurring_years: number
  recurring_months: number
  recurring_days: number
  recurring_km: number
  emails: string[]
  expired: boolean
  notify_limit_km: number
  notify_limit_date: number
  auto_generated: boolean
  amount: number;
  start_cost_date: number;
  end_cost_date: number;
  private _$stato: string;
  private _$type: string;
  private _$participant: Partecipant;
  private _$target: DeadlineTarget;
  private _$is_cost: boolean;

  private _apps: Partecipant[] = [];
  private _$remaining_km: number
  private _$remaining_days: number

  private $getParticipant: (id: string) => Partecipant = () => {return undefined};
  private $getTarget: (id: string) => DeadlineTarget = () => {return undefined};

  constructor(data?: any, options? : {
    getParticipant?: (id: string) => Partecipant,
    getTarget?: (id: string) => DeadlineTarget,
  }){
    if(data){
      this.setData(data, options)
    }
  }

  setData(data?: any, options?: {
    getParticipant?: (id: string) => Partecipant,
    getTarget?: (id: string) => DeadlineTarget,
  }){
    if(options){
      if(options.getParticipant) this.$getParticipant = options.getParticipant
      if(options.getTarget) this.$getTarget = options.getTarget
    }
    this._id = data._id
    this.id_organization = data.id_organization
    this.type = data.type
    this.$target = data.id_deadline_target
    this.$participant = data.id_participant
    this.title = data.title
    this.description = data.description
    this.description_cost = data.description_cost
    this.completed = data.completed
    this.completation_date = data.completation_date
    this.completation_km = data.completation_km
    this.recurring = data.recurring;
    if(data.recurring_type) this.recurring = true; // vecchia versione era così almeno le vecchie deadline funzionanop TODO: eliminare dopo fare si test
    this.recurring_years = data.recurring_years
    this.recurring_months = data.recurring_months
    this.recurring_days = data.recurring_days
    this.recurring_km = data.recurring_km
    this.emails = data.emails
    this.apps = data.apps
    this.expired = data.expired
    this.notify_limit_km = data.notify_limit_km
    this.notify_limit_date = data.notify_limit_date
    this.auto_generated = data.auto_generated
    this.created = data.created
    this.amount = data.amount
    this.start_cost_date = data.start_cost_date
    this.end_cost_date = data.end_cost_date
    this.$remaining_km = data.expiration_km;
    this.$remaining_days = data.expiration_date;
  }

  get $is_cost(): boolean{
    return !this.expiration_km && !this.expiration_date
  }

  get $stato(): string{
    return this._$stato;
  }

  set $stato(value: string){
    this._$stato = value;
  }

  get $type(): string{
    return this._$type;
  }

  set $type(value: string){
    this._$type = value;
  }

  get $participant(): any{
    return this._$participant;
  }

  set $participant(id: string){
    this._$participant = this.$getParticipant(id);
    this.id_participant = id;
  }

  get $target(): any{
    return this._$target;
  }

  set $target(id: string){
    if(id){
      this._$target = this.$getTarget(id)
      this.id_deadline_target = id
    }
  }

  get $remaining_km(): number{
    return this._$remaining_km;
  }

  set $remaining_km(value: number){
    // if(value !== null){
      this.expiration_km = value;
      if(this.$participant){
        this._$remaining_km = this.expiration_km - (this.$participant.$total_odometer_live / 1000);
      }
    // }
  }

  get $remaining_days(): number{
    return this._$remaining_days;
  }

  set $remaining_days(value: number){
    this.expiration_date = value
    const dataScadenza = moment(this.expiration_date);
    const dataCorrente = moment();
    this._$remaining_days = dataScadenza.diff(dataCorrente, 'days');
  }

  get apps(): any {
    return this._apps;
  }

  set apps(value: string[]){
    this._apps = [];
    if(value){
      for(let item of value){
        let participant = this.$getParticipant(item)
        if(participant) this._apps.push(participant)
      }

    }

  }

}


export class DeadlineTarget{

  _id: string;
  id_organization: string;
  surname: string;
  type: number;
  name: string;
  description: string;
  plate: string;
  created: number;
  label_number: number;
  private _$label: string


  constructor(data?: any){
    if(data){
      this.setData(data)
    }
  }

  setData(data?: any){
    if(data){
      this._id = data._id
      this.id_organization = data.id_organization
      this.surname = data.surname
      this.type = data.type
      this.name = data.name
      this.description = data.description
      this.plate = data.plate
      this.created = data.created
      this.label_number = 99999
    }
  }

  get $label(): string{
    if (this.name && this.plate) {
      return `${this.plate} ${this.name}`;
    } else if (this.name && this.surname) {
      return `${this.name} ${this.surname}`;
    } else if (this.name) {
      return this.name;
    } else if (this.plate) {
      return this.plate;
    } else if (this.surname) {
      return this.surname;
    } else {
      return '';
    }
  }

}
